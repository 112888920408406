<template>
  <div>
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo :decoded="decoded" @OpenMenuLateral="menuOpen = true"></Topo>

      <div class="container p-3 mb-5">
        <b-row>
          <b-col cols="1">
            <router-link class="link-black" tag="a" :to="'/dashboard'">
              <i class="fa fa-chevron-left text-success"></i>
            </router-link>
          </b-col>

          <b-col>
            <h5 class="m-4 text-center">Cobranças</h5>
          </b-col>
        </b-row>
        <b-list-group>
          <b-list-group-item variant="danger" v-if="boletos.length == 0"
            >Nenhuma Cobrança Encontrada</b-list-group-item
          >
          <b-list-group-item
            v-for="item in boletos"
            :key="item.id"
            :variant="`${
              item.situacao_boleto == 'BAIXADO' ||
              item.situacao_boleto == 'Finalizado'
                ? 'success'
                : ''
            }`"
          >
            {{ item.forma_pagamento }} - {{ item.tipo_boleto }} ({{
              item.valor_boleto | currency
            }}) -
            {{ item.situacao_boleto }}

            <br />
            <b-badge
              :variant="
                retornaClasseVencimento(
                  item.data_vencimento,
                  item.situacao_boleto,
                )
              "
              >Vencimento: {{ formataData(item.data_vencimento) }}</b-badge
            >
            <br />
            <b-badge variant="success" v-if="item.data_pagamento"
              >Pagamento {{ formataData(item.data_pagamento) }}</b-badge
            >
            <div
              class="mt-2"
              v-if="
                item.situacao_boleto != 'BAIXADO' &&
                item.situacao_boleto != 'Finalizado' &&
                validaVencimento(item.data_vencimento)
              "
            >
              <div
                v-if="item.forma_pagamento == 'Boleto' || !item.forma_pagamento"
              >
                <div v-if="item.idTipoBoleto !== 4">
                  <label>Linha digitável:</label>
                  <Br />
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Linha digitável"
                      aria-label="Linha digitável"
                      aria-describedby="button-addon2"
                      :value="item.linha_digitavel"
                    />
                    <div class="input-group-append">
                      <button
                        @click="copiarText(item.linha_digitavel)"
                        class="btn btn-dark"
                        type="button"
                        id="button-addon2"
                      >
                        <i class="fa fa-copy"></i>
                      </button>
                    </div>
                    <div class="input-group-append">
                      <!-- <a
                        target="_blank"
                        :href="`${item.linkBoleto}?insideApp=true`"
                        class="btn btn-success text-white"
                        id="button-addon2"
                      >
                        <i class="fa fa-download"></i>
                      </a> -->
                      <a
                        target="_blank"
                        :href="`${getWindow().replace('app', 'adm')}/Boleto/${
                          item.idBoleto
                        }/${item.nosso_numero}`"
                        class="btn btn-success text-white"
                        id="button-addon2"
                      >
                        <i class="fa fa-download"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div v-if="item.pixQrCode">
                  <label>Pix Copia e Cola:</label>
                  <Br />
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Pix"
                      aria-label="pix"
                      aria-describedby="button-addon2"
                      :value="item.pixQrCode"
                    />
                    <div class="input-group-append">
                      <button
                        @click="copiarText(item.pixQrCode)"
                        class="btn btn-dark"
                        type="button"
                        id="button-addon2"
                      >
                        <i class="fa fa-copy"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.forma_pagamento == 'Pix'">
                <VueQr :text="item.pixQrCode" />
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="QR Code"
                    aria-label="QR Code"
                    aria-describedby="button-addon2"
                    :value="item.pixQrCode"
                  />
                  <div class="input-group-append">
                    <button
                      @click="copiarText(item.pixQrCode, 'Pix')"
                      class="btn btn-dark"
                      type="button"
                      id="button-addon2"
                    >
                      <i class="fa fa-copy"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else-if="
                item.situacao_boleto != 'BAIXADO' &&
                item.situacao_boleto != 'Finalizado' &&
                item.situacao_boleto != 'CANCELADO' &&
                !validaVencimento(item.data_vencimento) &&
                !utils.verificaEmpresaBemProtege(decoded.id_empresa)
              "
            >
              <button
                @click="reativarPorBoleto(item)"
                class="btn btn-success btn-block mt-2"
                type="button"
                id="button-addon2"
              >
                Gerar Reativação
              </button>
            </div>
          </b-list-group-item>
        </b-list-group>
        <div class="m-5">&nbsp;</div>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './Topo';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import moment from 'moment';
import VueQr from 'vue-qr';
import utils from '../services/utils';
export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      utils,
      decoded: {},
      menuOpen: false,
      boletos: [],
      carregando: false,
      regulamento: null,
    };
  },
  watch: {},
  components: { Topo, ConteudoDash, MenuRodape, MenuLateral, VueQr },
  methods: {
    getWindow() {
      return window.location.origin;
    },
    copiarText(linha, tipo) {
      var msg = 'Linha digitável copiado para a área de Transfêrencia';
      if (tipo == 'Pix') msg = 'Qr Code copiado para a área de Transfêrencia';
      //O texto que será copiado
      const texto = linha;
      //Cria um elemento input (pode ser um textarea)
      let inputTest = document.createElement('input');
      inputTest.value = texto;
      //Anexa o elemento ao body
      document.body.appendChild(inputTest);
      //seleciona todo o texto do elemento
      inputTest.select();
      //executa o comando copy
      //aqui é feito o ato de copiar para a area de trabalho com base na seleção
      document.execCommand('copy');
      //remove o elemento
      document.body.removeChild(inputTest);
      this.$bvToast.toast(msg, {
        title: 'Atenção!',
        solid: true,
        variant: 'success',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    validaVencimento(data) {
      if (moment().diff(data, 'days') <= 5) return true;
      else return false;
    },
    retornaClasseVencimento(data, situacao) {
      if (situacao == 'BAIXADO' || situacao == 'Finalizado') return 'warning';
      else if (moment().diff(data, 'days') > 1) return 'danger';
    },
    formataId(id) {
      return id.toString().padStart(6, '0');
    },
    formataData(data) {
      const [date, _] = data.split('T');
      const [ano, mes, dia] = date.split('-');

      return `${dia}/${mes}/${ano}`;
    },
    formataDataUtc(data) {
      return moment(data).utc().format('DD/MM/YYYY');
    },
    formataDesde(data) {
      return moment(data).format('MM/YYYY');
    },
    formataAno(data) {
      return moment(data).format('YYYY');
    },
    reativarPorBoleto(item) {
      this.carregando = true;
      service
        .post('Boletos', 'boletoReativacao', item)
        .then((res) => {
          //this.boletos = res.data;
          this.carregando = false;
          var chave = res.data;
          this.$router.push({
            path: `/AceiteCotacao/${chave}`,
          });
        })
        .catch((e) => {
          this.carregando = false;
          this.$bvToast.toast(
            'Já existe uma Proposta em Aberto para essa Placa',
            {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 3500,
            },
          );
        });
    },
    buscaLink(nossoNumero) {
      return new Promise(async (resolve, reject) => {
        this.carregando = true;

        service
          .getAll('Boletos', {}, 'buscaBoletoLink/' + nossoNumero)
          .then((res) => {
            //this.boletos = res.data;
            this.carregando = false;
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
            this.carregando = false;
            this.erroConexao =
              'Seu aplicativo parece estar offline, verifique sua conexao';
          });
      });
    },
    buscaBoletos() {
      this.carregando = true;

      service
        .getAll('Boletos', {}, 'listSga')
        .then(async (res) => {
          var arrAux = res.data;

          for (let index = 0; index < arrAux.length; index++) {
            var element = arrAux[index];
            element.linkBoleto = '';
            if (
              element.situacao_boleto != 'BAIXADO' &&
              this.validaVencimento(element.data_vencimento)
            ) {
              if (!element.link_boleto) {
                var boleto = await this.buscaLink(element.nosso_numero);
                element.linkBoleto = boleto.link_boleto;
              } else {
                element.linkBoleto = element.link_boleto;
              }
            }
          }

          this.boletos = arrAux.filter((x) => x.situacao_boleto != 'EXCLUIDO');
          this.carregando = false;
        })
        .catch((e) => {
          this.carregando = false;
          this.erroConexao =
            'Seu aplicativo parece estar offline, verifique sua conexao';
        });
    },
    openDecoded() {
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
      }
    },
  },
  mounted() {
    this.openDecoded();
    this.buscaBoletos();
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow-x: hidden;
  background: none !important;
}
h5 {
  text-transform: uppercase;
  font-family: 'robotobold';
}
.btn {
  border-radius: 10px;
}
.badge {
  font-size: 90% !important;
}
</style>
